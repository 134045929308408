<template>
    <div v-if="isVisible" class="left-0 top-0 fixed w-screen h-screen bg-[#00000030] pb-[8px]" @click.self="close()">
        <div class="relative w-[364px] bg-[#FFF] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[20px] overflow-hidden">
            <div class="p-[16px_20px] border-b-[1px] border-b-[#E2E4E9] flex gap-[16px] items-center relative">
                <div v-if="iconUrl" class="p-[10px] w-max rounded-full border-[1px] border-[#E2E4E9]">
                    <img width="24" height="24" :src="iconUrl" alt="">
                </div>
                <span class="text-[#0A0D14] text-base font-medium">{{ title }}</span>
                <button class="absolute top-[16px] right-[20px]" @click="close()" :class="{'!static ml-auto': !iconUrl }">
                    <img width="20" height="20" src="@assets/images/icons/close-line.svg" alt="" />
                </button>
            </div>
            <Suspense>
                <component v-if="view" :is="view" :interface="store" :params="params"></component>
                <template #fallback>
                    <div class="p-[20px] flex flex-col gap-[12px]">
                        <span class="block bg-[#E5E7EB] h-[40px] w-full rounded-[8px] animate-pulse"></span>
                        <span class="block bg-[#E5E7EB] h-[40px] w-full rounded-[8px] animate-pulse"></span>
                    </div>
                </template>
            </Suspense>
        </div>
    </div>
</template>
<script>
import { getIconURL } from '@/utils/assets';
import ModalListSettings from './ModalListSettings.vue';
import ModalListRename from './ModalListRename.vue';
import ModalListDelete from './ModalListDelete.vue';
import ModalListCreate from './ModalListCreate.vue';
import ModalListProfileRemove from './ModalListProfileRemove.vue';
import ModalListProfileAdd from './ModalListProfileAdd.vue';

export default {
    props: {
        store: {
            type: Object,
            required: true
        }
    },
    components: {
        ModalListSettings,
        ModalListRename,
        ModalListDelete,
        ModalListCreate,
        ModalListProfileRemove,
        ModalListProfileAdd
    },
    data: () => ({
        isVisible: false,
        view: '',
        title: '',
        iconUrl: null,
        params: null
    }),
    mounted() {
        this.store.modal = this;
    },
    methods: {
        open() {
            this.isVisible = true;
            document.querySelector("body").style.overflow = "hidden";
        },
        close() {
            this.isVisible = false;
            this.view = '';
            this.title = '';
            this.iconUrl = null;
            document.querySelector("body").style.overflow = null;
        },
        setView(viewName, params=null) {
            this.view = viewName;
            this.params = params;
            this.open();
        },
        setHeader(title=null, icon=null) {
            this.title = title;
            this.iconUrl = getIconURL(icon);
        }
    }
}
</script>
